import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

class Video extends Component {
    constructor(props) {
        super(props)

        this.videoButton = createRef()
    }

    componentDidMount() {
        (function($, videoButton) {
            'use strict';
            $(videoButton).yu2fvl();
        })(jQuery, this.videoButton.current)
    }

    render() {
        const { thumbnail, url } = this.props

        return (
            <div className="brook-video-area pb--150 pb-md--80 pb-sm--60 pt--80 pt-md--80 pt-sm--60 bg_color--1 basic-thine-line">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="video-with-thumb text-center move-up wow">

                                <div className="thumb">
                                    <Img fixed={thumbnail.localFile.childImageSharp.fixed} />
                                </div>

                                <div className="video-btn position--center">
                                    <a ref={this.videoButton} className="play__btn" href={url}>
                                        <div className="video-icon"></div>
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Video.propTypes = {
    url: PropTypes.string.isRequired,
    thumbnail: PropTypes.shape({
        localFile: PropTypes.shape({
            childImageSharp: PropTypes.shape({
                fixed: PropTypes.object.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
}

export default Video

export const query = graphql`
    fragment Video on ContentfulVideoBlock {
        id
        title
        youTubeUrl
        thumbnail {
            localFile {
                childImageSharp {
                    fixed(width: 770, height: 500) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    }
`