import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import _ from 'lodash'

import SlickSlider from '../slick-slider'

class Testimonials extends SlickSlider {

    constructor(props) {
        super(props)

        this.slickSlider = createRef()
    }

    componentDidMount() {
        this.slickCode(this.slickSlider)
    }

    renderSlide = ({ id, quote, author, jobTitle, photo }) => (
        <div key={id} className="testimonial testimonial_style--1 hover-transparent space-large--topbottom bg-dark">
            <div className="content">
                <p className="bk_pra">“{quote.quote}”</p>
                <div className="testimonial-info">
                    <div className="post-thumbnail">
                        <Img fixed={photo.localFile.childImageSharp.fixed} />
                    </div>
                    <div className="clint-info">
                        <h6>{author}</h6>
                        {jobTitle ? <span>{jobTitle}</span> : ``}
                    </div>
                </div>
                <div className="testimonial-quote">
                    <span className="fa fa-quote-right"></span>
                </div>
            </div>
        </div>
    )

    render() {
        const { heading, slides } = this.props

        return (
            <div className="brook-testimonial-area bg_image--18">
                <div className="row row--0 align-items-center">
                    <div className="col-lg-3 col-xl-5 text-center ptb-md--80 ptb-sm--80">
                        <div className="brook-section-title text-left title-max-width plr_lg--15">
                            <h2 className="heading heading-h2 text-white line-height-1-42 pl_sm--40 pl_md--40 pl_lg--40 pl_lp--40">{heading}</h2>
                        </div>
                    </div>
                    <div className="col-lg-9 col-xl-7">
                        <div ref={this.slickSlider} className="brook-element-carousel slick-arrow-center slick-arrow-triggle slick-arrow-trigglestyle-2 testimonial-space-right testimonial-color-variation testimonial-bg-red"
                            data-slick-options='{
                                    "spaceBetween": 0, 
                                    "slidesToShow": 2, 
                                    "slidesToScroll": 1, 
                                    "arrows": true,
                                    "infinite": true,
                                    "centerMode":true,
                                    "dots": false,
                                    "prevArrow": {"buttonClass": "slick-btn slick-prev", "iconClass": "fas fa-angle-left" },
                                    "nextArrow": {"buttonClass": "slick-btn slick-next", "iconClass": "fas fa-angle-right" }
                                }' data-slick-responsive='[
                                    {"breakpoint":768, "settings": {"slidesToShow": 3}},
                                    {"breakpoint":669, "settings": {"slidesToShow": 2}},
                                    {"breakpoint":577, "settings": {"slidesToShow": 1}},
                                    {"breakpoint":481, "settings": {"slidesToShow": 1}}
                                ]'>

                                {_.map(slides, this.renderSlide)}
                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Testimonials.propTypes = {
    heading: PropTypes.string.isRequired,
    slides: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        quote: PropTypes.shape({
            quote: PropTypes.string.isRequired,
        }).isRequired,
        author: PropTypes.string.isRequired,
        jobTitle: PropTypes.string,
        photo: PropTypes.shape({
            localFile: PropTypes.shape({
                childImageSharp: PropTypes.shape({
                    fixed: PropTypes.object.isRequired,
                }).isRequired,
            }).isRequired,
        }).isRequired,
    })).isRequired,
}

export default Testimonials

export const query = graphql`
    fragment Testimonials on ContentfulTestimonialsBlock {
        id
        heading
        slides {
            id
            author
            jobTitle
            quote {
                quote
            }
            photo {
                localFile {
                    childImageSharp {
                        fixed(width: 60, height: 60) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    }
`