import React from 'react'
import PropTypes from 'prop-types'

const Title = ({ children }) => (
    <div className="pt--100 pt-md--60 pt-sm--60 pb--80 pb-md--60 pb-sm--60">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="brook-section-title text-center">
                        <h3 className="heading heading-h3 font-large">{children}</h3>
                        <div className="bkseparator--25"></div>
                        <div className="title-separator w--80 color-red"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

Title.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Title