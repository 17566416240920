import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import _ from 'lodash'

const LinkBoxesLarge = ({ title, data }) => {

    const renderLinkBox = ({ id, heading, iconImage, content, link }) => (
        <div key={id} className="col-lg-4 col-md-6 col-sm-6 col-12 wow move-up">
            <div className="icon-box text-center no-border with-padding mt--40">
                <div className="inner">
                    <div className="icon">
                        <Img fixed={iconImage.localFile.childImageSharp.fixed} style={{ borderRadius: `50%` }} />
                    </div>
                    <div className="content">
                        <h5 className="heading heading-h5">{heading}</h5>
                        {content ? <p className="bk_pra">{content.content}</p> : ``}
                        <a className="icon-btn color-red" href={link.url}><span>{link.text}</span><i
                                className="fa fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        <div id="section-our-services" className="brook-icon-boxes-area ptb--120 ptb-md--80 ptb-sm--80 bg_image--17">
            <div className="container">
                {title ? (<div className="row">
                    <div className="col-lg-12">
                        <div className="brook-section-title text-center">
                            <h3 className="heading heading-h3 font-large">{title}</h3>
                            <div className="bkseparator--25"></div>
                            <div className="title-separator w--80 color-red"></div>
                        </div>
                    </div>
                </div>) : ``}
                <div className="row mt--40">
                    {_.map(data, renderLinkBox)}
                </div>
            </div>
        </div>
    )
}

LinkBoxesLarge.defaultProps = {
    title: ``,
}

LinkBoxesLarge.propTypes = {
    title: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        heading: PropTypes.string.isRequired,
        iconImage: PropTypes.shape({
            title: PropTypes.string.isRequired,
            localFile: PropTypes.shape({
                childImageSharp: PropTypes.shape({
                    fixed: PropTypes.object.isRequired,
                }).isRequired,
            }).isRequired,
        }).isRequired,
        content: PropTypes.shape({
            content: PropTypes.string,
        }),
        link: PropTypes.shape({
            id: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
        }).isRequired,
    })).isRequired,
}

export default LinkBoxesLarge

export const query = graphql`
    fragment LinkBoxesLarge on ContentfulLargeLinkBoxesBlock {
        id
        title
        linkBoxes {
            id
            heading
            iconImage {
                title
                localFile {
                    childImageSharp {
                        fixed(width: 114, height: 114) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
            content {
                content
            }
            link {
                id
                url
                text
            }
        }
    }
`