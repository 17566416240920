import React from 'react'
import PropTypes from 'prop-types'

const TitleLarge = ({ children }) => (
    <div className="brook-breadcaump-area pt--100 pb--40 bg_color--1 breadcaump-title-bar">
        <div className="container-fluid plr--150 plr_sm--20">
            <div className="row">
                <div className="col-lg-12">
                    <div className="breadcaump-inner text-center">
                        <h1 className="heading heading-h1 line-height-1-5">{children}</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

TitleLarge.propTypes = {
    children: PropTypes.node.isRequired,
}

export default TitleLarge