/**
 * Page template
 *
 * @author Matt Carter <mcarter@vertic.com>
 */

import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import _ from 'lodash'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import LinkBoxes from '../components/link-boxes'
import LinkBoxesLarge from '../components/link-boxes-large'
import Testimonials from '../components/testimonials'
import Title from '../components/title'
import TextContent from '../components/text-content'
import Video from '../components/video'
import TitleLarge from '../components/title-large'
import TeamGrid from '../components/team-grid'

const Page = ({ data }) => {
    const pageData = data.contentfulPage

    const renderElement = (element) => {
        switch(element.__typename) {
            case 'ContentfulLargeLinkBoxesBlock':
                return (
                    <LinkBoxesLarge key={element.id} title={element.title} data={element.linkBoxes} />
                )
            case 'ContentfulLinkBoxesBlock':
                return (
                    <LinkBoxes key={element.id} data={element.linkBoxes} />
                )
            case 'ContentfulHeroBlock':
                return (
                    <Hero key={element.id} name={element.name} data={element.slides} />
                )
            case 'ContentfulTitleBlock':
                return (
                    <Title key={element.id}>{element.title}</Title>
                )
            case 'ContentfulLargeTitleBlock':
                return (
                    <TitleLarge key={element.id}>{element.title}</TitleLarge>
                )
            case 'ContentfulTextBlock':
                return (
                    <TextContent key={element.id} title={element.title} content={element.content} />
                )
            case 'ContentfulVideoBlock':
                return (
                    <Video key={element.id} thumbnail={element.thumbnail} url={element.youTubeUrl} />
                )
            case 'ContentfulTeamGridBlock':
                return (
                    <TeamGrid key={element.id} data={element.teamMembers} />
                )
            case 'ContentfulTestimonialsBlock':
                return (
                    <Testimonials key={element.id} heading={element.heading} slides={element.slides} />
                )
            default:
                return ``
        }
    }

    return (
        <Layout>
            <SEO title={pageData.name} />
            <main className="page-content">
                {_.map(pageData.elements, renderElement)}
            </main>
        </Layout>
    )
}

Page.propTypes = {
    data: PropTypes.shape({
        contentfulPage: PropTypes.shape({
            name: PropTypes.string.isRequired,
            elements: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
}

export default Page

export const query = graphql`
    query($name: String!) {
        contentfulPage(name: {eq: $name }) {
            name
            elements {
                __typename
                ... on ContentfulTitleBlock {
                    id
                    title
                }
                ... on ContentfulLargeTitleBlock {
                    id
                    title
                }
                ... on ContentfulTextBlock {
                    ...TextContent
                }
                ... on ContentfulVideoBlock {
                    ...Video
                }
                ... on ContentfulHeroBlock {
                    ...Hero
                }
                ... on ContentfulLinkBoxesBlock {
                    ...LinkBoxes
                }
                ... on ContentfulLargeLinkBoxesBlock {
                    ...LinkBoxesLarge
                }
                ... on ContentfulTeamGridBlock {
                    ...TeamGrid
                }
                ... on ContentfulTestimonialsBlock {
                    ...Testimonials
                }
            }
        }
    }
`