import React from  'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'

const richTextOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (_node, children) => <p className="bk_pra move-up wow">{children}</p>
    }
}

const TextContent = ({ title, content }) => (
    <div className="ptb--60">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="bk-text-box">
                        {title ? <h5 className="heading heading-5 move-up wow">{title}</h5> : ``}
                        <div className="spacing"></div>
                        {content ? documentToReactComponents(content.json, richTextOptions) : ``}
                    </div>
                </div>
            </div>
        </div>
    </div>
)

TextContent.defaultProps = {
    title: ``,
}

TextContent.propTypes = {
    title: PropTypes.string,
    content: PropTypes.shape({
        json: PropTypes.object,
    }).isRequired,
}

export default TextContent

export const query = graphql`
    fragment TextContent on ContentfulTextBlock {
        id
        title
        content {
            json
        }
    }
`