import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import _ from 'lodash'

import SlickSlider from '../slick-slider'

class Hero extends SlickSlider {
    constructor(props) {
        super(props)

        this.slickSlider = createRef()
    }

    componentDidMount() {
        this.slickCode(this.slickSlider)
    }

    renderSlide = ({ id, heading, subheading, image, link, textColour}) => (
        <div key={id} className="hero-item bg-image" style={{ backgroundImage: `url(${image.fixed.src})` }}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        
                        <div className="hero-content-3 left" style={{color: textColour}}>
                            <h3>{heading}</h3>
                            {subheading ? <h6>{subheading}</h6> : `` }
                            {link && link.text ? <a className="brook-btn btn-sd-size space-between btn-essential btn" href={link.url}>{link.text}</a> : ``}
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )

    render() {
        const { data } = this.props

        return (
            <div className="brook-hero-nav-slider-area">
                <div ref={this.slickSlider} className="brook-element-carousel slick-arrow-center slick-arrow-rounded lr-0" data-slick-options='{ 
                    "slidesToShow": 1, 
                    "slidesToScroll": 1, 
                    "arrows": true, 
                    "infinite": true,
                    "dots": false,
                    "prevArrow": {"buttonClass": "slick-btn slick-prev", "iconClass": "ion ion-ios-arrow-back" },
                    "nextArrow": {"buttonClass": "slick-btn slick-next", "iconClass": "ion ion-ios-arrow-forward" }
                }'>
            
                    {_.map(data, this.renderSlide)}
                    
                </div>
            </div>
        )
    }
}

Hero.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        heading: PropTypes.string.isRequired,
        subheading: PropTypes.string,
        textColour: PropTypes.string,
        image: PropTypes.shape({
            localFile: PropTypes.shape({
                childImageSharp: PropTypes.shape({
                    fixed: PropTypes.object,
                }),
            }),
        }),
        link: PropTypes.shape({
            url: PropTypes.string,
            text: PropTypes.string,
        })
    })).isRequired,
}

export default Hero

export const query = graphql`
    fragment Hero on ContentfulHeroBlock {
        id
        name
        slides {
            id
            heading
            subheading
            textColour
            image {
                fixed(width: 1920, height: 795) {
                    ...GatsbyContentfulFixed
                }
            }
            link {
                id
                url
                text
            }
        }
    }
`