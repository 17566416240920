import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import _ from 'lodash'

const TeamGrid = ({ data }) => {

    function openModal (e) {
        const [modal] = e.currentTarget.parentElement.getElementsByClassName('modal-wrapper');
        
        modal.classList.add('active');
        document.documentElement.style.overflow = 'hidden';
    }

    function closeModal (e) {
        let modal = e.currentTarget.parentElement;

        if (!modal.classList.contains('modal-wrapper')) {
            modal = e.currentTarget.parentElement.parentElement;
        }

        modal.classList.remove('active');
        document.documentElement.style.overflow = 'auto';
    }

    const renderTeamMember = (member) => (
        <div key={member.id} className="col-lg-4 col-md-4 col-sm-6 col-12">
            <div className="team team__style--3" onClick={openModal}>
                <div className="thumb">
                    <img src={member.photo.localFile.childImageSharp.fixed.src} alt={member.name} />
                    <div className="overlay" style={{ backgroundImage: member.photo.localFile.childImageSharp.fixed.src }}>
                        <p>{member.info}</p>
                    </div>
                    <ul className="social-icon icon-solid-rounded icon-size-medium text-center">
                        <li className="linkedin"><a href={member.linkedin} className="link" target="blank" aria-label="linkedin"><i
                                    className="fab fa-linkedin"></i></a></li>
                    </ul>
                </div>
                <div className="team-info text-center">
                    <div className="info">
                        <h5>{member.name}</h5>
                        <span className="title">{member.title}</span>
                    </div>
                </div>
            </div>

            <div className="modal-wrapper">
                <div className="modal-background" onClick={closeModal}></div>
                <div className="modal-content-box">
                    <div className="close-btn" onClick={closeModal}><i className="fas fa-times"></i></div>
                    
                    <div className="col-one">
                        <div className="thumb"><img src={member.photo.localFile.childImageSharp.fixed.src} alt={member.name} /></div>
                        <div className="basic-info">
                            <h5>{member.name}</h5>
                            <p className="title">{member.title}</p>
                            <p className="start-date">21/10/15</p>
                        </div>
                    </div>
                    <div className="info-col">
                        <div className="modal-info job-desc">
                            <h5>Job description</h5>
                            <ul>
                                <li>Head of PM team and project manager</li>
                            </ul>
                        </div>

                        <div className="modal-info key-skills">
                            <h5>Three key skills</h5>
                            <ul>
                                <li>COM projects</li>
                                <li>Project planning and budgeting</li>
                                <li>Team management</li>
                            </ul>
                        </div>

                        <div className="modal-info what-else">
                            <h5>What else can I do</h5>
                            <ul>
                                <li>Worked previously with IT</li>
                            </ul>
                        </div>
                    </div>

                    <div className="info-col">
                        <div className="modal-info project-clients">
                            <h5>Project/client I currently working on and projects I have worked on in the past</h5>
                            <ul>
                                <li>Leo Pharma</li>
                                <li>ROCKWOOL</li>
                                <li>Biogen</li>
                                <li>MSD/Merck</li>
                            </ul>
                        </div>

                        <div className="modal-info interest">
                            <h5>Interest</h5>
                            <ul>
                                <li>Gaming</li>
                                <li>Golf</li>
                                <li>Gin</li>
                            </ul>
                        </div>

                        <div className="modal-info Education">
                            <h5>Education</h5>
                            <ul>
                                <li>Applied Econometrics and Finance</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

    return (
        <div className="brook-team-area bg_color--1 ptb--120 ptb-md--80 ptb-sm--80">
            <div className="container">
                <div className="row row--30">

                    {_.map(data, renderTeamMember)}
                    
                </div>
            </div>

        </div>
    )
}

TeamGrid.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        title: PropTypes.string,
        photo: PropTypes.shape({
            localFile: PropTypes.shape({
                childImageSharp: PropTypes.shape({
                    fixed: PropTypes.object.isRequired,
                }).isRequired,
            }).isRequired,
        }).isRequired,
    })).isRequired,
}

export default TeamGrid

export const query = graphql`
    fragment TeamGrid on ContentfulTeamGridBlock {
        id
        name
        teamMembers {
            id
            name
            title
            info
            linkedin
            photo {
                localFile {
                    childImageSharp {
                        fixed(width: 340, height: 500) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    }
`