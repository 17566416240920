import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import _ from 'lodash'

const LinkBoxes = ({ data }) => {

    const renderLinkBox = ({ id, url, heading, icon, content }) => (
        <div key={id} className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
            <div className="icon-box text-center no-border bg-transparant vibrate-style">
                <a href={url} className="inner">
                    <div className="icon">
                        <i className={`fa fa-${icon}`}></i>
                    </div>
                    <div className="content">
                        <h5 className="heading heading-h5">{heading}</h5>
                        {content ? <p>{content.content}</p> : ``}
                    </div>
                </a>
            </div>
        </div>
    )

    return (
        <div className="brook-icon-boxes-area pb--100 pb-md--60 pb-sm--60 pt--80 pt-md--60 pt-sm--60">
            <div className="container">
                <div className="row">
                    {_.map(data, renderLinkBox)}
                </div>
            </div>
        </div>
    )
}

LinkBoxes.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        heading: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        content: PropTypes.shape({
            content: PropTypes.string,
        }),
    })).isRequired,
}

export default LinkBoxes

export const query = graphql`
    fragment LinkBoxes on ContentfulLinkBoxesBlock {
        id
        name
        linkBoxes {
            id
            url
            heading
            icon
            content {
                content
            }
        }
    }
`